import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { History, Location } from 'history'
import { find, findIndex, first } from "lodash"
import React, { useContext, useRef, useState } from 'react'
import { Button, Row } from 'reactstrap'

import { ClientReportDocumentsQuery, Exact, File as Document, FileTypeSubTypeMap, UpdateFileMetadataInput, User, useUpdateFileMetadataMutation, MeFragment } from '../../__generated__/graphql'
import Auth from '../../Auth/Auth'
import { convertLookupToString, excludePropertyArray } from '../../helpers/object'
import { DisplayDocs, getDocumentPermission } from '../Shared/Document'
import RouteLeavingGuard from '../Shared/RouteLeavingGuard'
import EditButtons from '../ui/EditButtons'
import { documentTableData } from './ClientDocumentList'
import { EditButtonContext } from '../../Context/EditButtonContext'
import { useEffect } from 'react'
import { ApolloQueryResult } from '@apollo/client'

const UrlMapping: {[type: string]: string} = {
  "documents": "documents",
  "default": "documents",
  "managerdocs": "managerdocs",
  "reports": "reports",
}

type idProps = {
  clientId: number
  history: History
  location: Location
  auth: Auth
  allDocuments: documentTableData[]
  filteredDocuments: documentTableData[]
  user?: MeFragment
  match: documentMatch
  typeMapping: FileTypeSubTypeMap[]
  refetchQuery: (variables?: Exact<{id: number;}> | undefined) => Promise<ApolloQueryResult<ClientReportDocumentsQuery>>
  associationType: "Client" | "Plan"
  planId?: number
  urlType?: string
  notEditable?: boolean // no edit btn, no upload/delete version/file, no add/delete associations
}

type documentMatch = {
  params: {
    documentId: string
  }
}

const ClientDocumentDetails: React.FC<idProps> = (props) => {
  // const match = useRouteMatch() as documentMatch
  // const { documentId } = match.params
  const auth = props.auth
  const documentId = props.match.params.documentId
  const document = find(props.allDocuments, ["id", documentId])
  let urlType = props.urlType? UrlMapping[props.urlType]: UrlMapping["default"]
  if(!document){
    props.history.replace(`/clients/${props.clientId}/${props.planId}/${urlType}`)
  }
  return (
    <div>
      {!!document &&
        <DocumentShow
          auth={auth}
          document={document as Document}
          typeMapping={props.typeMapping}
          refetchQuery={props.refetchQuery}
          user={props.user}
          history={props.history}
          filteredDocuments={props.filteredDocuments}
          clientId={props.clientId}
          documentId={documentId}
          associationType={props.associationType}
          planId={props.planId}
          urlType={urlType}
          notEditable={!!props?.notEditable}
        />
      }
    </div>
  )
}

interface DocumentShowProps {
  auth: Auth
  document: Document
  typeMapping: FileTypeSubTypeMap[]
  refetchQuery: any
  user?: MeFragment
  history: History
  filteredDocuments: documentTableData[]
  clientId: number
  documentId: string
  planId?: number
  associationType: "Client" | "Plan"
  urlType: string
  notEditable?: boolean
}

const DocumentShow: React.FC<DocumentShowProps> = ({ auth, document, typeMapping, refetchQuery, user, history, filteredDocuments, clientId, documentId, associationType, planId, urlType, notEditable}) => {
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)

  const resultRef = useRef<DisplayDocs>(null)
  const [hasError, setHasError] = useState(false)

  const [updateFileMutation] = useUpdateFileMetadataMutation()
  const documentIndex = findIndex(filteredDocuments, ["id", documentId])
  const documentPermissions = getDocumentPermission({document: document as Document, page: "Client", auth})
  const context = useContext(EditButtonContext)
  const { setOptions, showButtons, resetErrors } = context
  const editable = documentPermissions.edit && !notEditable
  if(showButtons !== editable)
    setOptions({showButtons: editable})
  useEffect(() => {
    resetErrors()
  }, [resetErrors])

  const handleSubmit = () => {
    const currentDocument = resultRef!.current?.state.currentState
    if(!documentPermissions.edit){
      return
    }
    if(!currentDocument){
      return
    }
    if(hasError){
      return
    }
    setSaving(true)
    const { id, ...rest } = currentDocument
    const patch = { ...rest }
    let formattedPatch = convertLookupToString(
      patch,
      false,
    )
    let updateData = excludePropertyArray(formattedPatch, ["__typename", "name", "versions", "body", "mimeType", "versionId", "url", "lastUpdated", "clientId", "reportId", "associations", "planId"])
    updateData.managers = updateData.managers.map((ob:any) => ob.id)
    updateData.plans = updateData.plans.map((ob:any) => ob.id)
    updateData.products = updateData.products.map((ob:any) => ob.product.id)
    updateData.vehicles = updateData.vehicles.map((ob:any) => ob.vehicle.id)
    updateData.glidePaths = updateData.glidePaths.map((ob:any) => ob.id)
    updateData.clients = updateData.clients.map((ob:any) => ob.id)

    const input = { id, patch: updateData } as UpdateFileMetadataInput
    updateFileMutation({ variables: { input } })
      .then(result => {
        if (result && result.data) {
          //let unformattedNewData = { assets: result.data.assets?.org }
          setEditMode(false)
          setSaving(false)
        }
      })
      .catch(err => {
        setSaving(false)
        console.log("Error testManagerSummary", err.message)
        // throw new Error(`${err.message}`)
      })
  }

  const handleDelete = () => {
    history.push(`/clients/${clientId}${planId ? `/${planId}` : ""}/${urlType}`)
    refetchQuery()
  }

  const heading = (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <div className="pane pane-toolbar sticky-top above-picker">
        <div className="mr-2 pr-3 border-right">
          <Button color="blue-link" className="ml-auto mt-0 mb-0" onClick={() => history?.push("/clients/" + clientId + (planId ? `/${planId}` : "") + `/${urlType}`)}>
            <FontAwesomeIcon
              icon={"chevron-left"}
              className="mr-2 "
              size="sm"
            />
            Documents + Reports
          </Button>
        </div>
        <div className="mr-2 pr-3 border-right d-flex flex-nowrap align-items-center">
          <Button color="blue-link" className="mr-2" disabled={documentIndex < 1} onClick={() => {
            let usedUrlType = urlType
            if(filteredDocuments[documentIndex-1].__typename === "Report"){
              usedUrlType = "reports"
            }
            history?.push("/clients/" + clientId + (planId ? `/${planId}` : "") + `/${usedUrlType}/` + filteredDocuments[documentIndex-1].id)}}>
            <FontAwesomeIcon
              icon={"chevron-left"}
              className=""
              size="sm"
            />
          </Button>
          {documentIndex+1}
          {" "} Of {" "}
          {filteredDocuments.length}
          <Button color="blue-link" className="ml-2" disabled={documentIndex >= filteredDocuments.length -1} onClick={() => {
            let usedUrlType = urlType
            if(filteredDocuments[documentIndex+1].__typename === "Report"){
              usedUrlType = "reports"
            }
            history?.push("/clients/" + clientId + (planId ? `/${planId}` : "") + `/${usedUrlType}/` + filteredDocuments[documentIndex+1].id)}}>
            <FontAwesomeIcon
              icon={"chevron-right"}
              className=""
              size="sm"
            />
          </Button>
        </div>
        <EditButtons editMode={editMode} setEditMode={setEditMode} saving={saving} onSubmit={handleSubmit} disableOnError={true} hideOnContext={true}/>
      </div>
    </>
  )

  return (
    <>
      {heading}
      <Row>
        <DisplayDocs
          editMode={editMode}
          setEditMode={setEditMode}
          document={document as Document}
          typeMapping={typeMapping as FileTypeSubTypeMap[]}
          ref={resultRef}
          user={user}
          auth={auth}
          refetchQuery={refetchQuery}
          hasError={(value:boolean) => setHasError(value)}
          fullWidth={true}
          associationType={associationType}
          associationId={associationType === "Client" ? clientId : planId}
          removeDocument={handleDelete}
          notEditable={notEditable}
        />
      </Row>
    </>
  )
}


export default ClientDocumentDetails