import classnames from 'classnames'
import React, {EventHandler, useContext, useEffect, useState} from 'react'

import { CustomInput, FormGroup, Label } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { EditButtonContext } from '../../../Context/EditButtonContext'
import { InputTooltip } from './TextFieldInput'

interface RadioPillInputProps {
  idx: string | number,
  property: string,
  displayName: string,
  propertyValue: string | boolean | undefined,
  editMode: boolean,
  updateValue: (event:any) => void
  readonly?: boolean
  wrapperClasses?: string,
  labelClasses?: string,
  inputWrapperClasses?: string,
  inputClasses?: string,
  required?: boolean
  tooltip?: InputTooltip
  options: {code: string | number, value: string}[]
  disabled?: boolean
}

export const RadioPillInput: React.FC<RadioPillInputProps> = props => {
  const { idx, property, displayName, propertyValue, editMode, labelClasses, inputWrapperClasses, inputClasses, tooltip, options, disabled } = props
  let updateValue: EventHandler<any> = props.updateValue

  let required = props.required || false
  let shortProperty = property.split(".").pop()
  shortProperty = shortProperty ? shortProperty : property
  let [wrapperClasses, setWrapperClasses] = useState(props.wrapperClasses || '')
  const context = useContext(EditButtonContext)
  const { setError } = context
  const displayLabel = (displayName !== "")

  useEffect(() => {
    if(required){
      if(propertyValue === undefined || propertyValue === null){
        setWrapperClasses(wrapperClasses + ' has-error')
        setError(`${idx}-${shortProperty}`, ["Presence"])
      } else {
        setError(`${idx}-${shortProperty}`, [])
        setWrapperClasses(wrapperClasses.replace(/has-error/g,''))
      }
    }
    return () => {
      setError(`${idx}-${shortProperty}`, [])
    }
  }, [editMode, idx, propertyValue, required])

  return (
    <FormGroup
      className={classnames("form-group row", wrapperClasses||"")}
      key={idx}
      name={`${idx}-${shortProperty}`}
      validate={{ required: { value: true, errorMessage: 'Please select'}}}
    >
      {displayLabel &&
        <Label
          className={classnames("col-form-label", labelClasses||"", {"col-sm-4": !labelClasses})}
          htmlFor={`${idx}-${shortProperty}`}
          id={property}
        >
          <div
            className={classnames("d-flex w-100", {'tooltip-icon': tooltip, 'justify-content-start': tooltip?.onClick})}
            id={tooltip ? tooltip.id : ""}
          >
            {displayName}
            {tooltip &&
              <FontAwesomeIcon
                icon={tooltip.icon as IconName}
                size="sm"
                onClick={() => { tooltip.onClick && tooltip.onClick() }}
              />
            }
          </div>
        </Label>
      }
      <div
        className={classnames("exportable-form-input d-flex align-items-center justify-content-evenly ",  inputWrapperClasses||"", {"col-sm-4": !inputWrapperClasses})}
      >
        <div className={classnames("d-flex align-items-center justify-content-evenly pill-radio-holder", {"w-100": inputWrapperClasses?.includes("w-100")})}>
          {options.map((option, optionidx) => {
            return(<CustomInput
              key={optionidx}
              className={classnames(inputClasses, "custom-control-inline pill-radio flex-grow-1", {
                hidden: false // (!editMode && propertyValue !== true)
              })}
              id={`${idx}-${shortProperty}-${option.code}`}
              bsSize="sm"
              type="radio"
              value={option.code}
              checked={propertyValue === option.code}
              onChange={() => {
                updateValue(option.code)
              }}
              label={option.value}
              inline
              disabled={!editMode || disabled}
            />)
          })}
        </div>
      </div>
    </FormGroup>
  )
}
