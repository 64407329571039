import React, { useState, useEffect } from "react"
import moment from "moment"
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Form,
  Input,
} from "reactstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DATE_API_FORMAT } from "../../../helpers/constant"
import {
  useVehicleManagementFeeSchedulesQuery,
  DatedFeeSchedule,
  SeparateAccount,
  PooledVehicle,
  HedgeFundVehicle,
  DatedHedgeFundFeeSchedule,
  FeeSchedule as IFeeSchedule,
  HedgeFundFeeSchedule,
  FeeBalance,
  HedgeFundFeeBalance,
} from "../../../__generated__/graphql"
import PlaceHolder from "../../ui/PlaceHolder"
import FeeSchedule from "./FeeSchedule"
import {
  FeeHistoryModal,
  FeeHistoryData,
  FeeHistoryRow,
} from "./FeeHistoryModal"
import { formatCurrency, formatPercent, previousQuarterEnd } from "./helper"
import { FeeToggle } from "./FeeToggle"
import _ from "lodash"
import { PercentInput } from "./FeeFormComponents"

export type FeeScheduleComponent =
  | Omit<IFeeSchedule, "__typename">
  | Omit<HedgeFundFeeSchedule, "__typename">

export type FeeBalanceComponent =
  | Omit<FeeBalance, "__typename">
  | Omit<HedgeFundFeeBalance, "__typename">

export interface FeeScheduleData {
  date?: string | null
  feeSchedule?: FeeScheduleComponent[] | null
  feeBalance?: FeeBalanceComponent | null
}

export interface FeeScheduleTiers {
  smallest: FeeScheduleComponent
  tiers: FeeScheduleComponent[]
  balance: FeeBalanceComponent
}

interface ManagementFeeProps {
  handleInputChange: (value: any, property: string) => void
  editing: boolean
  fundid: string
}

export const ManagementFees: React.FC<ManagementFeeProps> = ({
  handleInputChange,
  editing,
  fundid,
}) => {
  const quarterEndDate = previousQuarterEnd
  const [searchDate, setSearchDate] = useState(quarterEndDate)
  const [flatFee, setFlatFee] = useState(false)
  const [rawDatedFeeSchedule, setRawDatedFeeSchedule] = useState<
    (DatedFeeSchedule | DatedHedgeFundFeeSchedule)[]
  >([])
  const [feeScheduleTiers, setFeeScheduleTiers] = useState<FeeScheduleTiers>()
  const [performanceFee, setPerformanceFee] = useState(false)
  const [showHistory, toggleHistoryModal] = useState(false)
  const [feeBalance, setFeeBalance] = useState<
    FeeBalanceComponent | null | undefined
  >()

  const { error, loading, data } = useVehicleManagementFeeSchedulesQuery({
    variables: {
      fundid: fundid,
    },
    fetchPolicy: "no-cache",
  })

  const toggle = () => toggleHistoryModal(!showHistory)

  const getFeeTiers = (
    data?: FeeScheduleData | undefined
  ): FeeScheduleTiers => {
    let smallest: FeeScheduleComponent = {
        accountSize: 0,
        managementFee: 0,
      },
      balance = {
        managementFee: 0,
      },
      tiers: FeeScheduleComponent[] = []
    if (data) {
      const { feeSchedule, feeBalance } = data
      if (feeSchedule) {
        feeSchedule.sort((a, b) => b.managementFee - a.managementFee)
        if (feeSchedule.length > 0) {
          smallest = feeSchedule[0]
          balance = JSON.parse(JSON.stringify(smallest))
        }
        if (feeSchedule.length > 1) {
          let accountSize = feeSchedule[0].accountSize
          tiers = feeSchedule
            .filter((_, idx) => idx !== 0)
            .map((item) => {
              let performanceFee
              const managementFee = item.managementFee
              const hedgeFeeScheduleItem = item as HedgeFundFeeSchedule
              accountSize += item.accountSize
              if (hedgeFeeScheduleItem.performanceFee) {
                performanceFee = hedgeFeeScheduleItem.performanceFee
              }
              return {
                accountSize,
                managementFee,
                performanceFee,
              }
            })
          balance = JSON.parse(JSON.stringify(tiers[tiers.length - 1]))
        }
      }
      if (feeBalance) {
        balance = feeBalance
      }
    }
    return { smallest, tiers, balance }
  }

  useEffect(() => {
    //if the user changes to flat fee, then remove the fee schedule data
    if (flatFee) {
      handleInputChange([], `vehicle.feeSchedule.feeSchedule`)
    }
  }, [flatFee])

  useEffect(() => {
    const managementFee = feeBalance?.managementFee
      ? +feeBalance.managementFee
      : null
    let performanceFee
    if ((feeBalance as HedgeFundFeeBalance)?.performanceFee !== undefined) {
      if ((feeBalance as HedgeFundFeeBalance).performanceFee) {
        performanceFee = +((feeBalance as HedgeFundFeeBalance)
          .performanceFee as number)
      }
    }
    handleInputChange(
      {
        date: quarterEndDate,
        feeBalance: {
          managementFee,
          performanceFee,
        },
      },
      `vehicle.feeSchedule`
    )
  }, [feeBalance])

  useEffect(() => {
    setPerformanceFee(false)
    if (data?.vehicle) {
      const separateAccount = (data?.vehicle as SeparateAccount).separateAccount
      const pooledVehicle = (data?.vehicle as PooledVehicle).pooledVehicle
      const hedgeFundVehicle = (data?.vehicle as HedgeFundVehicle)
        .hedgeFundVehicle
      let datedFeeSchedule:
        | (DatedFeeSchedule | DatedHedgeFundFeeSchedule)[]
        | null = null
      let feeSchedule:
        | (DatedFeeSchedule | DatedHedgeFundFeeSchedule)
        | null
        | undefined
      if (separateAccount?.feeSchedule) {
        datedFeeSchedule = separateAccount.feeSchedule as DatedFeeSchedule[]
      } else if (pooledVehicle?.feeSchedule) {
        datedFeeSchedule = pooledVehicle.feeSchedule as DatedFeeSchedule[]
      } else if (hedgeFundVehicle?.feeSchedule) {
        datedFeeSchedule =
          hedgeFundVehicle.feeSchedule as DatedHedgeFundFeeSchedule[]
        setPerformanceFee(true)
      }
      if (datedFeeSchedule) {
        setRawDatedFeeSchedule(datedFeeSchedule)
        feeSchedule = datedFeeSchedule
          .sort((a, b) => {
            const dateA = a?.date
            const dateB = b?.date
            if (!dateA || !dateB) return 0
            if (dateA < dateB) return 1
            if (dateA === dateB) return 0
            return -1
          })
          .find((item) => {
            if (!item.date) return false
            return true
          })
        //if there isn't a fee balance for the current date...
        if (feeSchedule && !feeSchedule.feeBalance) {
          /* check if there was a fee balance from a previous date and use that
          even if there isn't any fee balance, the highest tier will be used as the fee balance.
          That logic is in the "getFeeTiers" function above */
          const feeBalance = datedFeeSchedule.find((item) => item.feeBalance)
          if (feeBalance?.feeBalance) {
            feeSchedule.feeBalance = feeBalance.feeBalance
          }
        }
      }
      if (feeSchedule) {
        const feeScheduleTiers = getFeeTiers(feeSchedule)
        setFeeScheduleTiers(feeScheduleTiers)
        setSearchDate(feeSchedule.date)
        setFeeBalance(feeSchedule?.feeBalance)
        //if there isn't a fee schedule from the latest date, consider it a flat fee
        if (!feeSchedule.feeSchedule?.length) {
          setFlatFee(true)
        } else {
          setFlatFee(false)
        }
      } else {
        const feeScheduleTiers = getFeeTiers()
        setFeeScheduleTiers(feeScheduleTiers)
        setSearchDate(quarterEndDate)
        setFlatFee(false)
        setFeeBalance(feeScheduleTiers.balance)
      }
    } else {
      const feeScheduleTiers = getFeeTiers()
      setFeeScheduleTiers(feeScheduleTiers)
      setSearchDate(quarterEndDate)
      setFlatFee(false)
      setFeeBalance(feeScheduleTiers.balance)
    }
  }, [loading, data, quarterEndDate])

  const onChangeCallback = (value: FeeScheduleData) => {
    handleInputChange({ date: quarterEndDate, ...value }, `vehicle.feeSchedule`)
  }

  const getFeeHistoryRows = (): FeeHistoryRow[] => {
    return rawDatedFeeSchedule.map((feeScheduleItem) => {
      const date = feeScheduleItem.date
        ? moment(feeScheduleItem.date, DATE_API_FORMAT).format("MM/DD/YYYY")
        : null
      const { smallest, tiers, balance } = getFeeTiers(feeScheduleItem)
      const data: (string | null)[][] = []
      const formattedSmallestAcctSize = formatCurrency(
        smallest.accountSize,
        editing
      )
      const smallestRow: (string | null)[] = [
        `Less than ${formattedSmallestAcctSize}`,
        formatPercent(smallest.managementFee, editing),
      ]
      if (performanceFee) {
        let performanceFee = null
        if ((smallest as HedgeFundFeeSchedule).performanceFee) {
          performanceFee = formatPercent(
            (smallest as HedgeFundFeeSchedule).performanceFee as number,
            editing
          )
        }
        smallestRow.push(performanceFee)
      }
      data.push(smallestRow)
      tiers.forEach((tier, idx) => {
        let formattedLow = null
        let formattedHigh = formatCurrency(tier.accountSize, editing)
        if (idx === 0) {
          formattedLow = formattedSmallestAcctSize
        } else {
          formattedLow = formatCurrency(tiers[idx - 1].accountSize, editing)
        }
        const tierRow: (string | null)[] = [
          `between ${formattedLow} - ${formattedHigh}`,
          formatPercent(tier.managementFee, editing),
        ]
        if (performanceFee) {
          let performanceFee = null
          if ((tier as HedgeFundFeeSchedule).performanceFee) {
            performanceFee = formatPercent(
              (tier as HedgeFundFeeSchedule).performanceFee as number,
              editing
            )
          }
          tierRow.push(performanceFee)
        }
        data.push(tierRow)
      })
      let formattedBalanceAcctSize = formattedSmallestAcctSize
      if (tiers.length > 0) {
        formattedBalanceAcctSize = formatCurrency(
          tiers[tiers.length - 1].accountSize,
          editing
        )
      }
      const balanceRow: (string | null)[] = [
        `More than ${formattedBalanceAcctSize}`,
        formatPercent(balance.managementFee, editing),
      ]
      if (performanceFee) {
        let performanceFee = null
        if ((balance as HedgeFundFeeSchedule).performanceFee) {
          performanceFee = formatPercent(
            (balance as HedgeFundFeeSchedule).performanceFee as number,
            editing
          )
        }
        balanceRow.push(performanceFee)
      }
      data.push(balanceRow)
      return {
        date,
        data,
      }
    })
  }

  const feeHistoryData: FeeHistoryData = {
    headers: ["ACCOUNT BALANCE", "MANAGEMENT FEE"],
    rows: getFeeHistoryRows(),
  }

  if (performanceFee) {
    feeHistoryData.headers.push("PERFORMANCE FEE")
  }

  return (
    <>
      <Card>
        {" "}
        {error ? (
          <div>{error.message}</div>
        ) : loading ? (
          <PlaceHolder />
        ) : data?.vehicle ? (
          <>
            {" "}
            <CardHeader className="with-input">
              <Row style={{ display: "table" }}>
                <Col className={"pl-1"} style={{ display: "table-cell" }}>
                  Management Fees{" "}
                  {!editing && (
                    <FontAwesomeIcon
                      onClick={toggle}
                      icon="history"
                      className="ml-2"
                    />
                  )}
                </Col>
                <Col className="form-inline">
                  <FormGroup>
                    <Label className={"text-nowrap"} for="datedFeesDate" sm={2}>
                      As of{" "}
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="date"
                        bsSize="sm"
                        disabled
                        name="datedFeesDate"
                        value={editing ? quarterEndDate : searchDate}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              {editing && (
                <>
                  <div className="border-bottom-line-break row my-2"></div>
                  <FeeToggle
                    onChange={setFlatFee}
                    toggle={flatFee}
                    displayOptions={{
                      title: "Fee Structure",
                      default: "Flat fee for all accounts",
                      alternate: "Tiered pricing",
                    }}
                  />
                </>
              )}
            </CardHeader>
            <CardBody className={"fee-card"}>
              {flatFee ? (
                /* fix fake editable input on viewMode*/
                <Form inline className="ml-2 pl-3 my-2 form-group">
                  <FormGroup className="mr-sm-2">
                    <Label className="mr-sm-2">Flat Fee</Label>
                    <PercentInput
                      value={feeBalance?.managementFee}
                      disabled={!editing}
                      onChange={(value) => {
                        const balance = JSON.parse(JSON.stringify(feeBalance))
                        _.set(balance, "managementFee", value)
                        setSearchDate(quarterEndDate)
                        setFeeBalance(balance)
                      }}
                    />
                  </FormGroup>
                  {performanceFee && (
                    <FormGroup className="mr-sm-2">
                      <Label className="mr-sm-2">Performance Fee</Label>
                      <PercentInput
                        value={
                          (feeBalance as HedgeFundFeeBalance)?.performanceFee
                        }
                        disabled={!editing}
                        onChange={(value) => {
                          const balance = JSON.parse(JSON.stringify(feeBalance))
                          _.set(balance, "performanceFee", value)
                          setSearchDate(quarterEndDate)
                          setFeeBalance(balance)
                        }}
                      />
                    </FormGroup>
                  )}
                </Form>
              ) : feeScheduleTiers ? (
                <FeeSchedule
                  performanceFee={performanceFee}
                  editing={editing}
                  feeScheduleTiers={feeScheduleTiers}
                  onChangeCallback={onChangeCallback}
                />
              ) : (
                <PlaceHolder />
              )}
            </CardBody>{" "}
          </>
        ) : (
          <div>Data not found</div>
        )}
      </Card>
      <FeeHistoryModal
        isOpen={showHistory}
        title="Management fee structure"
        toggle={toggle}
        data={feeHistoryData}
      />
    </>
  )
}
