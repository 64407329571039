import { useEffect, useMemo, useRef, useState } from 'react'
import {
  Card,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  CardHeader,
  CardBody,
  Table,
  Form,
  FormFeedback,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  checkIfSet,
  formatCurrency,
  formatPercent,
  getMaxDate,
  previousQuarterEnd as quarterEndDate,
} from './helper'
import { Portfolio } from './ProductVehicles'
import { CurrencyInput, PercentInput } from './FeeFormComponents'
import { FeeToggle } from './FeeToggle'
import {
  ClientPortfolioDatedFee,
  ClientPortfolioFeeSchedule,
} from '../../../__generated__/graphql'
import classNames from 'classnames'
import { FeeHistoryModal, FeeHistoryRow } from './FeeHistoryModal'
import moment from 'moment'
import { DATE_API_FORMAT } from '../../../helpers/constant'
import { ExtraRow } from '../../../helpers/exportTable'
import numbro from 'numbro'
import { FormInput } from '../../ui/Forms/FormInput'

const twoQuartersAgoEnd = moment()
  .subtract(2, 'quarter')
  .endOf('quarter')
  .format(DATE_API_FORMAT)

interface FeeRowProps {
  label: string
  value: number | null
  disabled: boolean
  onChange: (value: string) => void
}
//<FormInput
//propertyVal={value}
//editMode={!disabled}
//type={"percent"}
//updateValue={onChange}
//property={label}
//idx={label}              />
const FeeRow: React.FC<FeeRowProps> = ({
  label,
  value,
  disabled,
  onChange,
}) => {
  return (
    <tr>
      <td>
        <Form>
          <FormGroup row className="fee-group">
            <Label sm={8} className="pl-3">{label}</Label>
            <Col sm={4}>
              <PercentInput
                value={value}
                disabled={disabled}
                onChange={onChange}>
              </PercentInput>
            </Col>
          </FormGroup>
        </Form>
      </td>
    </tr>
  )
}

interface FormattedFeeSchedule {
  smallest: {
    lessThanAccountSize: number | null | undefined
    managementFee: number | null | undefined
  }
  largest: {
    moreThanAccountSize: number | null | undefined
    managementFee: number | null | undefined
  }
  tiers: Array<{
    accountSizeFrom: number | null | undefined
    accountSizeTo: number | null | undefined
    managementFee: number | null | undefined
  }>
}

/**
 * This function formats a fee schedule for a client's portfolio.
 *
 * It first sorts the fee schedule by management fee in descending order,
 * filtering out any entries where the management fee is null or undefined.
 *
 * It then identifies the smallest and largest account sizes and removes them from the array.
 *
 * The function then creates an array of the tiers, calculating the account size range and management fee for each tier.
 *
 * Finally, it returns an object containing the smallest and largest account sizes and the array of tiers.
 *
 * It is used to format the fee schedule for display in the PortfolioFees component.
 *
 * @param {Array<ClientPortfolioFeeSchedule | null>} feeSchedule - The fee schedule to format.
 * @returns {FormattedFeeSchedule} - The formatted fee schedule.
 */
function formatFeeSchedule(
  feeSchedule: Array<ClientPortfolioFeeSchedule | null>
): FormattedFeeSchedule {
  //sort feeSchedule by management fee, descending. managementFee can also be null or undefined, so we need to handle that
  const sortedFeeSchedule = feeSchedule
    .filter((fee) => checkIfSet(fee?.managementFee))
    .sort((a, b) => {
      if (a?.managementFee && b?.managementFee) {
        return b.managementFee - a.managementFee
      }
      return 0
    })

  // get the smallest and largest account sizes, and remove them from the array
  // smallest and largest should look like this
  const smallestRaw = sortedFeeSchedule.shift()
  const smallest = {
    lessThanAccountSize: smallestRaw?.accountSize,
    managementFee: smallestRaw?.managementFee,
  }
  const largestRaw = sortedFeeSchedule.pop()
  const largest = {
    moreThanAccountSize:
      sortedFeeSchedule.length > 0
        ? (smallestRaw?.accountSize || 0) +
          sortedFeeSchedule.reduce((acc, fee) => {
            return acc + (fee?.accountSize || 0)
          }, 0)
        : smallestRaw?.accountSize,
    managementFee: largestRaw?.managementFee,
  }

  // create an array of the tiers
  let balance = smallest?.lessThanAccountSize || 0
  const tiers = sortedFeeSchedule.map((fee, idx) => {
    const tier = {
      accountSizeFrom: balance,
      accountSizeTo: (fee?.accountSize || 0) + balance,
      managementFee: fee?.managementFee,
    }
    balance += fee?.accountSize || 0
    return tier
  })

  return {
    smallest,
    largest,
    tiers,
  }
}

/**
 * This function formats a fee schedule for a portfolio.
 *
 * It first checks if the smallest account size and its management fee are valid,
 * if so, it adds an entry to the fee schedule with these details.
 *
 * It then iterates over the tiers, checking if the account size range and management fee are valid for each tier,
 * if so, it adds an entry to the fee schedule with these details.
 *
 * Finally, it checks if the largest account size and its management fee are valid,
 * if so, it adds an entry to the fee schedule with these details.
 *
 * The function then returns the fee schedule.
 *
 * It is used to convert the fee schedule back from the format used for display, so the updated
 * data can be added to the portfolio and then used in the mutation to update the portfolio.
 *
 * @param {FormattedFeeSchedule} feeSchedule - The fee schedule to format.
 * @returns {Array<ClientPortfolioFeeSchedule | null>} - The formatted fee schedule.
 */
function formatFeeScheduleForPortfolio({
  smallest,
  tiers,
  largest,
}: FormattedFeeSchedule): Array<ClientPortfolioFeeSchedule | null> {
  const feeSchedule: Array<ClientPortfolioFeeSchedule | null> = []

  if (
    checkIfSet(smallest.lessThanAccountSize) &&
    checkIfSet(smallest.managementFee)
  ) {
    feeSchedule.push({
      date: '9999-12-31',
      managementFee: smallest.managementFee,
      accountSize: smallest.lessThanAccountSize,
      __typename: 'ClientPortfolioFeeSchedule',
    })
  }

  tiers.forEach((tier) => {
    if (
      checkIfSet(tier.accountSizeFrom) &&
      checkIfSet(tier.accountSizeTo) &&
      checkIfSet(tier.managementFee)
    ) {
      feeSchedule.push({
        date: '9999-12-31',
        managementFee: tier.managementFee,
        accountSize: (tier?.accountSizeTo || 0) - (tier?.accountSizeFrom || 0),
        __typename: 'ClientPortfolioFeeSchedule',
      })
    }
  })

  if (
    checkIfSet(largest.moreThanAccountSize) &&
    checkIfSet(largest.managementFee)
  ) {
    feeSchedule.push({
      date: '9999-12-31',
      managementFee: largest.managementFee,
      accountSize: 1_000_000_000_000,
      __typename: 'ClientPortfolioFeeSchedule',
    })
  }

  return feeSchedule
}

const formatFeeScheduleHistory = (
  data: Array<ClientPortfolioFeeSchedule | null> | null | undefined
) => {
  if (!data) return []
  const feeHistoryData: FeeHistoryRow[] = []
  const uniqueDates = data.reduce((acc, fee) => {
    if (fee?.date && !acc.includes(fee.date)) {
      acc.push(fee.date)
    }
    return acc
  }, [] as string[])

  uniqueDates.forEach((date) => {
    const dateData = data.filter((fee) => fee?.date === date)
    const { smallest, largest, tiers } = formatFeeSchedule(dateData || [])
    const dateFeeData: (string | null)[][] = []
    if (
      smallest &&
      checkIfSet(smallest.lessThanAccountSize) &&
      checkIfSet(smallest.managementFee)
    ) {
      const formattedSmallest = formatCurrency(
        smallest.lessThanAccountSize as number
      )
      dateFeeData.push([
        `Less than ${formattedSmallest}`,
        formatPercent(smallest.managementFee as number),
      ])
    }

    tiers.forEach((tier) => {
      if (
        checkIfSet(tier.accountSizeFrom) &&
        checkIfSet(tier.accountSizeTo) &&
        checkIfSet(tier.managementFee)
      ) {
        const formattedFrom = formatCurrency(tier.accountSizeFrom as number)
        const formattedTo = formatCurrency(tier.accountSizeTo as number)
        dateFeeData.push([
          `Between ${formattedFrom} - ${formattedTo}`,
          formatPercent(tier.managementFee as number),
        ])
      }
    })

    if (
      largest &&
      checkIfSet(largest.moreThanAccountSize) &&
      checkIfSet(largest.managementFee)
    ) {
      const formattedLargest = formatCurrency(
        largest.moreThanAccountSize as number
      )
      dateFeeData.push([
        `More than ${formattedLargest}`,
        formatPercent(largest.managementFee as number),
      ])
    }

    feeHistoryData.push({
      date: moment(date, DATE_API_FORMAT).format('MM/DD/YYYY'),
      data: dateFeeData,
    })
  })

  return feeHistoryData
}

const formatDatedFeeHistory = (
  data: Array<ClientPortfolioDatedFee | null> | null | undefined,
  portfolioType?: string
) => {
  if (!data) return []
  const dataCopy = JSON.parse(JSON.stringify(data)) as
    | Array<ClientPortfolioDatedFee | null>
    | null
    | undefined
  return (
    dataCopy
      ?.sort((a, b) => {
        if (a?.date && b?.date) {
          return b.date.localeCompare(a.date)
        } else {
          return 0
        }
      })
      ?.filter(
        (fee) =>
          checkIfSet(
            fee?.annualizedSubAdvisedPctOfApplicableSubadvisedAssets
          ) ||
          checkIfSet(fee?.annualizedSubAdvisedPctOfTotalPortfolio) ||
          checkIfSet(fee?.annualizedWrapPctOfApplicableWrappedAssets) ||
          checkIfSet(fee?.annualizedWrapPctOfTotalPortfolio) ||
          (portfolioType ===
            'OpenEndedCollectiveInvestmentFundStableValueComposite' &&
            checkIfSet(fee?.totalExpense))
      )
      .map((fee) => {
        const data: (string | null)[][] = [
          [
            'Annualized Sub-Advised (% of applicable sub-advised)',
            fee?.annualizedSubAdvisedPctOfApplicableSubadvisedAssets
              ? formatPercent(
                  fee?.annualizedSubAdvisedPctOfApplicableSubadvisedAssets
                )
              : null,
          ],
          [
            'Annualized Wrap (% of applicable wrapped assets)',
            fee?.annualizedWrapPctOfApplicableWrappedAssets
              ? formatPercent(fee?.annualizedWrapPctOfApplicableWrappedAssets)
              : null,
          ],
        ]
        if (portfolioType === 'OpenEndedSeparateAccountStableValue') {
          data.push([
            'Annualized Sub-Advised (% of total portfolio)',
            fee?.annualizedSubAdvisedPctOfTotalPortfolio
              ? formatPercent(fee?.annualizedSubAdvisedPctOfTotalPortfolio)
              : null,
          ])
          data.push([
            'Annualized Wrap (% of total portfolio)',
            fee?.annualizedWrapPctOfTotalPortfolio
              ? formatPercent(fee?.annualizedWrapPctOfTotalPortfolio)
              : null,
          ])
        }
        if (
          portfolioType ===
          'OpenEndedCollectiveInvestmentFundStableValueComposite'
        ) {
          data.push([
            'Total Expense',
            fee?.totalExpense ? formatPercent(fee?.totalExpense) : null,
          ])
        }
        return {
          date: moment(fee?.date, DATE_API_FORMAT).format('MM/DD/YYYY'),
          data,
        }
      }) || []
  )
}

interface PortfolioFeesProps {
  portfolio: Portfolio
  setPortfolio: React.Dispatch<React.SetStateAction<Portfolio | null>>
  editing: boolean
}

export const PortfolioFees: React.FC<PortfolioFeesProps> = ({
  portfolio,
  setPortfolio,
  editing,
}) => {
  const [showScheduleHistory, toggleScheduleHistoryModal] = useState(false)
  const [showDatedHistory, toggleDatedHistoryModal] = useState(false)
  const [flatFee, setFlatFee] = useState(false)
  type TierError = {
    accountSize?: null | string
    managementFee?: null | string
  }
  const [errors, setErrors] = useState<{
    smallest: null | string
    tiers: null | TierError[]
    largest: null | string
  }>({
    smallest: null,
    tiers: null,
    largest: null,
  })
  const initialFeeScheduleEmpty = useRef(
    !portfolio.feeSchedule || portfolio.feeSchedule?.length === 0
  )

  // if portfolio.id changes, that means the user selected a different
  // portfolio, so need to reset the initialFeeScheduleEmpty ref
  // based on the state of the new portfolio, but only on initial load of the new portfolio
  useEffect(() => {
    initialFeeScheduleEmpty.current =
      !portfolio.feeSchedule || portfolio.feeSchedule?.length === 0
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolio.id])

  const latestFeeDate = getMaxDate(portfolio.datedFees)
  const portfolioType = portfolio.relatedVehicle?.__typename
  const latestFees = portfolio?.datedFees?.find(
    (fee) => fee?.date === (editing ? quarterEndDate : latestFeeDate)
  )
  const latestFeeSchedule = useMemo(
    () =>
      portfolio?.feeSchedule?.filter((item) => item?.date === '9999-12-31') ||
      [],
    [portfolio?.feeSchedule]
  )

  useEffect(() => {
    if (latestFeeSchedule && !editing) {
      if (latestFeeSchedule.length > 1) {
        setFlatFee(false)
      } else {
        setFlatFee(true)
      }
    }
  }, [latestFeeSchedule, editing])

  const { smallest, largest, tiers } = formatFeeSchedule(
    latestFeeSchedule || []
  )

  const createOnFeeChangeFunction =
    (feeType: keyof ClientPortfolioDatedFee) => (fee: string) => {
      setPortfolio((portfolio) => {
        if (!portfolio) return null
        if (!portfolio?.datedFees) return { ...portfolio }
        const portfolioCopy = JSON.parse(JSON.stringify(portfolio)) as Portfolio
        const feeIdx = portfolioCopy?.datedFees?.findIndex(
          (fee) => fee?.date === quarterEndDate
        )
        if (
          feeIdx !== undefined &&
          feeIdx !== -1 &&
          portfolioCopy?.datedFees?.[feeIdx]
        ) {
          ;(portfolioCopy.datedFees[feeIdx] as ClientPortfolioDatedFee)[
            feeType
          ] = +fee
        } else {
          if (portfolioCopy.datedFees) {
            portfolioCopy.datedFees.push({
              date: quarterEndDate,
              [feeType]: +fee,
              __typename: 'ClientPortfolioDatedFee',
            })
          } else {
            portfolioCopy.datedFees = [
              {
                date: quarterEndDate,
                [feeType]: +fee,
                __typename: 'ClientPortfolioDatedFee',
              },
            ]
          }
        }
        return {
          ...portfolioCopy,
        }
      })
    }

  const updatePortfolioFeeSchedule = (
    feeSchedule: Array<ClientPortfolioFeeSchedule | null>
  ) => {
    setPortfolio((prevPortfolio) => {
      // Create a deep copy of the previous portfolio
      const portfolio: Portfolio = JSON.parse(JSON.stringify(prevPortfolio))

      // if portfolio.feeSchedule has a date = previousQuarterEnd,
      // and a fee date = 9999-12-31, replace any existing fees
      // with date = 9999-12-31. Otherwise, if there is a fee
      // with date = 9999-12-31, set that fee's date to previousQuarterEnd
      // and add a new fee with date = 9999-12-31
      const scheduleHasCurrentQuarter = portfolio?.feeSchedule?.some(
        (fee) => fee?.date === twoQuartersAgoEnd
      )
      if (scheduleHasCurrentQuarter || initialFeeScheduleEmpty.current) {
        // remove any fees with date = 9999-12-31
        portfolio.feeSchedule = portfolio.feeSchedule?.filter(
          (fee) => fee?.date !== '9999-12-31'
        )
      } else {
        // update any rows with date = 9999-12-31
        // to date = previousQuarterEnd
        portfolio.feeSchedule = portfolio.feeSchedule?.map((fee) => {
          if (fee?.date === '9999-12-31') {
            fee.date = twoQuartersAgoEnd
          }
          return fee
        })
      }

      // add new fee schedule rows
      portfolio.feeSchedule?.push(...feeSchedule)

      return portfolio
    })
  }

  const validateFeeSchedule = ({ smallest, tiers }: FormattedFeeSchedule) => {
    const errors: {
      smallest: null | string
      tiers: null | TierError[]
      largest: null | string
    } = {
      smallest: null,
      tiers: null,
      largest: null,
    }
    if (tiers.length > 0) {
      if (
        smallest?.lessThanAccountSize &&
        tiers[0]?.accountSizeTo &&
        smallest?.lessThanAccountSize >= tiers[0]?.accountSizeTo
      ) {
        errors.smallest = "Must be less than first tier's account size"
      }
    }

    const tierErrors: TierError[] = []
    tiers.forEach((tier, idx) => {
      if (idx < tiers.length - 1) {
        if (tier.accountSizeTo && tiers[idx + 1].accountSizeTo) {
          if (tier.accountSizeTo >= (tiers[idx + 1].accountSizeTo as number)) {
            tierErrors[idx] = {
              ...tierErrors[idx],
              accountSize: 'Must be less than the next tier',
            }
          }
        }
      }
    })
    errors.tiers = tierErrors
    setErrors(errors)
  }
  const exportName = portfolio.relatedVehicle?.vehicle?.product?.product?.name
  let extraRows: ExtraRow[] = []
  extraRows.push({ values: ['Account Balance', '', '', 'Management Fee'] })
  if (smallest) {
    extraRows.push({
      values: [
        'Less than',
        numbro(smallest?.lessThanAccountSize).format('$0,0.00'),
        '',
        numbro(smallest?.managementFee).format('0.00%'),
      ],
    })
  }
  tiers.forEach((tier, idx) => {
    console.log(idx)
      const lessValue =
        idx === 0
          ? smallest?.lessThanAccountSize &&
            formatCurrency(smallest?.lessThanAccountSize, false)
          : formatCurrency(tiers[idx - 1]?.accountSizeFrom || 0, false)
      extraRows.push({
        values: [
          'Between',
          numbro(tier?.accountSizeFrom).format('$0,0.00'),
          numbro(tier?.accountSizeTo).format('$0,0.00'),
          numbro(tier?.managementFee).format('0.00%'),
        ],
      })
  })
  if(largest) {
    extraRows.push({
      values: [
        'More than',
        numbro(largest?.moreThanAccountSize).format('$0,0.00'),
        '',
        numbro(largest?.managementFee).format('0.00%'),
      ],
    })
  }

  return (
    <>
      <div className="pane">
        <Row>
          <Col>
            <Card className="with-input">
              <CardHeader>
                <Row style={{ display: 'table' }}>
                  <Col style={{ display: 'table-cell' }}>
                    Portfolio Fees{' '}
                    {!editing && (
                      <FontAwesomeIcon
                        onClick={() => {
                          toggleScheduleHistoryModal(
                            (historyModal) => !historyModal
                          )
                        }}
                        icon="history"
                        className="ml-2 cursor-pointer"
                      />
                    )}
                  </Col>
                  <Col style={{ display: 'table-cell' }}>
                    <FormGroup row>
                      <Label
                        className={"text-nowrap"}
                        for="datedFeesDate"
                        style={{ whiteSpace: 'nowrap' }}
                        sm={2}
                      >
                        As of
                      </Label>
                      <Col sm={10}>
                        <Input
                          type="date"
                          bsSize="sm"
                          disabled
                          name="datedFeesDate"
                          value={quarterEndDate}
                          style={{ minWidth: '110px' }}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                {editing && (
                  <>
                    <div className="border-bottom-line-break row my-2"></div>
                    <FeeToggle
                      onChange={setFlatFee}
                      toggle={flatFee}
                      displayOptions={{
                        title: 'Fee Structure',
                        default: 'Flat fee',
                        alternate: 'Tiered pricing',
                      }}
                    />
                  </>
                )}
              </CardHeader>
              <CardBody className="fee-card">
                {flatFee ? (
                  /* fix fake editable input on viewMode */
                  <Form inline className="ml-2 pl-3 my-2 form-group">
                    <FormGroup className="fee-card">
                      <Label className="mr-sm-2">Flat Fee</Label>
                      <FormInput
                        subClasses={{ wrapperClasses: "no-export-form" }}
                        propertyVal={latestFeeSchedule?.length === 1
                          ? latestFeeSchedule?.[0]?.managementFee || null
                          : null}
                        editMode={editing}
                        updateValue={(fee) => {
                          setPortfolio((portfolio) => {
                            if (!portfolio)
                              return null
                            if (!portfolio.feeSchedule)
                              return { ...portfolio }
                            // if portfolio.feeSchedule has a date = previousQuarterEnd,
                            // and a fee date = 9999-12-31, replace any existing fees
                            // with date = 9999-12-31. Otherwise, if there is a fee
                            // with date = 9999-12-31, set that fee's date to previousQuarterEnd
                            // and add a new fee with date = 9999-12-31
                            const scheduleHasCurrentQuarter = portfolio?.feeSchedule?.some(
                              (fee) => fee?.date === twoQuartersAgoEnd
                            )

                            if (scheduleHasCurrentQuarter) {
                              // remove any fees with date = 9999-12-31
                              portfolio.feeSchedule =
                                portfolio.feeSchedule?.filter(
                                  (fee) => fee?.date !== '9999-12-31'
                                )
                            } else {
                              // update any rows with date = 9999-12-31
                              // to date = previousQuarterEnd
                              portfolio.feeSchedule =
                                portfolio.feeSchedule?.map((fee) => {
                                  if (fee?.date === '9999-12-31') {
                                    fee.date = twoQuartersAgoEnd
                                  }
                                  return fee
                                })
                            }
                            // add a new fee with date = 9999-12-31
                            portfolio.feeSchedule?.push({
                              date: '9999-12-31',
                              managementFee: +fee,
                              accountSize: 1000000000000,
                              __typename: 'ClientPortfolioFeeSchedule',
                            })
                            return portfolio
                          })
                        } }
                        property={"Flat Fee"}
                        type={'percent'}
                        idx={'Flat Fee'}
                      />
                    </FormGroup>
                  </Form>
                ) : (
                  <>
                  <div className="exportable-form" data-export-name={"Management Fees"} data-export-settings={JSON.stringify({extraRows})}>
                  </div>
                  <Table className={'fee-table'} data-export-name="Product Portfolio">
                    <thead>
                      <tr>
                        <th>ACCOUNT BALANCE</th>
                        <th>
                          <div
                            id="managementFeeTooltip"
                            className="tooltip-icon sl-active-node-hand"
                          >
                            MANAGEMENT FEE{' '}
                            <FontAwesomeIcon icon="question-circle" size="sm" />
                          </div>
                        </th>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {smallest &&
                        checkIfSet(smallest.lessThanAccountSize) &&
                        checkIfSet(smallest.managementFee) && (
                          <tr>
                            <td>
                              <Form>
                                <FormGroup row className="fee-group">
                                  <Label sm={2}>Less than</Label>
                                  <Col sm={5}>
                                    <CurrencyInput
                                      value={smallest.lessThanAccountSize}
                                      disabled={!editing}
                                      invalid={!!errors.smallest}
                                      onChange={(value) => {
                                        const updatedLargest =
                                          tiers.length === 0
                                            ? {
                                                ...largest,
                                                moreThanAccountSize: +value,
                                              }
                                            : largest
                                        const formattedFeeSchedule = {
                                          smallest: {
                                            ...smallest,
                                            lessThanAccountSize: +value,
                                          },
                                          tiers: tiers.map((tier, idx) => {
                                            if (idx === 0) {
                                              return {
                                                ...tier,
                                                accountSizeFrom: +value,
                                              }
                                            } else {
                                              return tier
                                            }
                                          }),
                                          largest: updatedLargest,
                                        }
                                        validateFeeSchedule(
                                          formattedFeeSchedule
                                        )
                                        updatePortfolioFeeSchedule(
                                          formatFeeScheduleForPortfolio(
                                            formattedFeeSchedule
                                          )
                                        )
                                      }}
                                    />
                                    <FormFeedback>
                                      {errors.smallest}
                                    </FormFeedback>
                                  </Col>
                                </FormGroup>
                              </Form>
                            </td>
                            <td>
                              <FormGroup row>
                                <Col sm={12}>
                                  <PercentInput
                                    value={smallest.managementFee}
                                    disabled={!editing}
                                    onChange={(value) => {
                                      // ensure the value is greater than the first tier's management fee (if there is one)
                                      // otherwise, ensure the value is greater than the largest management fee
                                      const firstTier = tiers[0]
                                      let firstTierManagementFee = null
                                      if (firstTier) {
                                        firstTierManagementFee =
                                          firstTier.managementFee
                                      } else if (largest) {
                                        firstTierManagementFee =
                                          largest.managementFee
                                      }
                                      if (
                                        firstTierManagementFee &&
                                        +value <= firstTierManagementFee
                                      ) {
                                        value = (
                                          firstTierManagementFee + 0.0001
                                        ).toString()
                                      }
                                      updatePortfolioFeeSchedule(
                                        formatFeeScheduleForPortfolio({
                                          smallest: {
                                            ...smallest,
                                            managementFee: +value,
                                          },
                                          tiers,
                                          largest,
                                        })
                                      )
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                            </td>
                            <td></td>
                          </tr>
                        )}
                      {tiers.map((tier, idx) => (
                        <tr
                          key={tier.managementFee}
                          className={classNames({
                            'last-tier-row':
                              editing && idx === tiers.length - 1,
                          })}
                        >
                          <td>
                            <Form>
                              <FormGroup row className="fee-group">
                                <Label sm={2}>Between</Label>
                                <Col sm={5}>
                                  <CurrencyInput
                                    value={tier.accountSizeFrom}
                                    disabled
                                  />
                                </Col>
                                <Col sm={5}>
                                  <CurrencyInput
                                    value={tier.accountSizeTo}
                                    disabled={!editing}
                                    invalid={!!errors.tiers?.[idx]?.accountSize}
                                    onChange={(value) => {
                                      let updatedLargest = largest
                                      const formattedFeeSchedule = {
                                        smallest,
                                        tiers: tiers.map((tier, tierIdx) => {
                                          if (tierIdx === idx) {
                                            if (tiers[tierIdx + 1]) {
                                              const nextTier =
                                                tiers[tierIdx + 1]
                                              nextTier.accountSizeFrom = +value
                                            } else {
                                              updatedLargest = {
                                                ...largest,
                                                moreThanAccountSize: +value,
                                              }
                                            }
                                            return {
                                              ...tier,
                                              accountSizeTo: +value,
                                            }
                                          }
                                          return tier
                                        }),
                                        largest: updatedLargest,
                                      }
                                      validateFeeSchedule(formattedFeeSchedule)
                                      updatePortfolioFeeSchedule(
                                        formatFeeScheduleForPortfolio(
                                          formattedFeeSchedule
                                        )
                                      )
                                    }}
                                  />
                                  <FormFeedback>
                                    {errors.tiers?.[idx]?.accountSize}
                                  </FormFeedback>
                                </Col>
                              </FormGroup>
                            </Form>
                          </td>
                          <td>
                            <FormGroup row>
                              <Col sm={12}>
                                <PercentInput
                                  value={tier.managementFee}
                                  disabled={!editing}
                                  invalid={!!errors.tiers?.[idx]?.managementFee}
                                  onChange={(value) => {
                                    // ensure the value is greater than the next tier's management fee
                                    // and less than the previous tier's management fee (if there is one)
                                    const nextTier = tiers[idx + 1]
                                    const prevTier = tiers[idx - 1]
                                    let nextTierManagementFee = null
                                    let prevTierManagementFee = null
                                    if (nextTier) {
                                      nextTierManagementFee =
                                        nextTier.managementFee
                                    } else if (largest) {
                                      nextTierManagementFee =
                                        largest.managementFee
                                    }
                                    if (prevTier) {
                                      prevTierManagementFee =
                                        prevTier.managementFee
                                    } else if (smallest) {
                                      prevTierManagementFee =
                                        smallest.managementFee
                                    }
                                    if (
                                      checkIfSet(nextTierManagementFee) &&
                                      +value <=
                                        (nextTierManagementFee as number)
                                    ) {
                                      value = (
                                        (nextTierManagementFee as number) +
                                        0.0001
                                      ).toString()
                                    }
                                    if (
                                      checkIfSet(prevTierManagementFee) &&
                                      +value >=
                                        (prevTierManagementFee as number)
                                    ) {
                                      value = (
                                        (prevTierManagementFee as number) -
                                        0.0001
                                      ).toString()
                                    }
                                    updatePortfolioFeeSchedule(
                                      formatFeeScheduleForPortfolio({
                                        smallest,
                                        tiers: tiers.map((tier, tierIdx) => {
                                          if (tierIdx === idx) {
                                            return {
                                              ...tier,
                                              managementFee: +value,
                                            }
                                          }
                                          return tier
                                        }),
                                        largest,
                                      })
                                    )
                                  }}
                                />
                                <FormFeedback>
                                  {errors.tiers?.[idx]?.managementFee}
                                </FormFeedback>
                              </Col>
                            </FormGroup>
                          </td>
                          <td>
                            {editing && idx === tiers.length - 1 && (
                              <FontAwesomeIcon
                                icon="trash"
                                className="cursor-pointer"
                                onClick={() => {
                                  updatePortfolioFeeSchedule(
                                    formatFeeScheduleForPortfolio({
                                      smallest,
                                      tiers: tiers.filter(
                                        (_, tierIdx) => tierIdx !== idx
                                      ),
                                      largest,
                                    })
                                  )
                                }}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                      {largest &&
                        checkIfSet(largest.moreThanAccountSize) &&
                        checkIfSet(largest.managementFee) && (
                          <tr>
                            <td>
                              <Form>
                                <FormGroup row className="fee-group">
                                  <Label sm={2}>More Than</Label>
                                  <Col sm={5}>
                                    <CurrencyInput
                                      value={largest.moreThanAccountSize}
                                      disabled
                                    />
                                  </Col>
                                </FormGroup>
                              </Form>
                            </td>
                            <td>
                              <FormGroup row>
                                <Col sm={12}>
                                  <PercentInput
                                    value={largest.managementFee}
                                    disabled={!editing}
                                    onChange={(value) => {
                                      // ensure the value is less than the last tier's management fee (if there is one)
                                      // otherwise, ensure the value is greater than the smallest management fee
                                      const lastTier = tiers[tiers.length - 1]
                                      let lastTierManagementFee = null
                                      if (lastTier) {
                                        lastTierManagementFee =
                                          lastTier.managementFee
                                      } else if (smallest) {
                                        lastTierManagementFee =
                                          smallest.managementFee
                                      }
                                      if (
                                        lastTierManagementFee &&
                                        +value >= lastTierManagementFee
                                      ) {
                                        value = (
                                          lastTierManagementFee - 0.0001
                                        ).toString()
                                      }
                                      updatePortfolioFeeSchedule(
                                        formatFeeScheduleForPortfolio({
                                          smallest,
                                          tiers,
                                          largest: {
                                            ...largest,
                                            managementFee: +value,
                                          },
                                        })
                                      )
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                            </td>
                            <td></td>
                          </tr>
                        )}
                      {editing && (
                        <tr>
                          <td
                            colSpan={3}
                            className="add-tier-row cursor-pointer"
                            onClick={() => {
                              const lastTier = tiers[tiers.length - 1]
                              const accountSizeFrom =
                                lastTier?.accountSizeTo ??
                                largest.moreThanAccountSize ??
                                0
                              const accountSizeTo =
                                (lastTier?.accountSizeTo ??
                                  largest.moreThanAccountSize ??
                                  0) + 1
                              const managementFee =
                                (lastTier?.managementFee ??
                                  smallest.managementFee ??
                                  0.0002) - 0.0001

                              const updatedTiers = [
                                ...tiers,
                                {
                                  accountSizeFrom,
                                  accountSizeTo,
                                  managementFee,
                                },
                              ]

                              let largestManagementFee = largest.managementFee
                              if (
                                largestManagementFee !== null &&
                                largestManagementFee !== undefined
                              ) {
                                if (largestManagementFee >= managementFee)
                                  largestManagementFee = managementFee - 0.0001
                              } else {
                                largestManagementFee = managementFee - 0.0001
                              }

                              const feeScheduleToFormat = {
                                smallest,
                                tiers: updatedTiers,
                                largest: {
                                  ...largest,
                                  moreThanAccountSize: accountSizeTo,
                                  managementFee: largestManagementFee,
                                },
                              }

                              const formattedFeeSchedule =
                                formatFeeScheduleForPortfolio(
                                  feeScheduleToFormat
                                )
                              updatePortfolioFeeSchedule(formattedFeeSchedule)
                            }}
                          >
                            <b>Add Another Tier</b>{' '}
                            <FontAwesomeIcon
                              icon="plus-circle"
                              className="ml-1"
                              style={{
                                color: '#055f8e',
                              }}
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {[
          'OpenEndedCollectiveInvestmentFundStableValueComposite',
          'OpenEndedSeparateAccountStableValue',
        ].includes(portfolioType || '') && (
          <Row className="mt-3">
            <Col>
              <Card className="with-input">
                <CardHeader>
                  <Row style={{ display: 'table' }}>
                    <Col className={"pl-1"} style={{ display: 'table-cell' }}>
                      Stable Value Fees{' '}
                      {!editing && (
                        <FontAwesomeIcon
                          onClick={() => {
                            toggleDatedHistoryModal(
                              (historyModal) => !historyModal
                            )
                          }}
                          icon="history"
                          className="ml-2 cursor-pointer"
                        />
                      )}
                    </Col>
                    <Col style={{ display: 'table-cell' }}>
                      <FormGroup row>
                        <Label
                          for="datedFeesDate"
                          style={{ whiteSpace: 'nowrap' }}
                          sm={2}
                        >
                          As of
                        </Label>
                        <Col sm={10}>
                          <Input
                            type="date"
                            bsSize="sm"
                            disabled
                            name="datedFeesDate"
                            value={editing ? quarterEndDate : latestFeeDate}
                            style={{ minWidth: '110px' }}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className={'fee-card exportable'} data-export-name={'Stable Value Fees'}>
                  <Table
                    className={'fee-table'}
                  >
                    <tbody>
                      <FeeRow
                        label="Annualized Sub-Advised (% of applicable sub-advised)"
                        value={
                          latestFees?.annualizedSubAdvisedPctOfApplicableSubadvisedAssets ||
                          null
                        }
                        disabled={!editing}
                        onChange={createOnFeeChangeFunction(
                          'annualizedSubAdvisedPctOfApplicableSubadvisedAssets'
                        )}
                      />
                      <FeeRow
                        label="Annualized Wrap (% of applicable wrapped assets)"
                        value={
                          latestFees?.annualizedWrapPctOfApplicableWrappedAssets ||
                          null
                        }
                        disabled={!editing}
                        onChange={createOnFeeChangeFunction(
                          'annualizedWrapPctOfApplicableWrappedAssets'
                        )}
                      />
                      {portfolioType ===
                        'OpenEndedSeparateAccountStableValue' && (
                        <>
                          <FeeRow
                            label="Annualized Sub-Advised (% of total portfolio)"
                            value={
                              latestFees?.annualizedSubAdvisedPctOfTotalPortfolio ||
                              null
                            }
                            disabled={!editing}
                            onChange={createOnFeeChangeFunction(
                              'annualizedSubAdvisedPctOfTotalPortfolio'
                            )}
                          />
                          <FeeRow
                            label="Annualized Wrap (% of total portfolio)"
                            value={
                              latestFees?.annualizedWrapPctOfTotalPortfolio ||
                              null
                            }
                            disabled={!editing}
                            onChange={createOnFeeChangeFunction(
                              'annualizedWrapPctOfTotalPortfolio'
                            )}
                          />
                        </>
                      )}
                      {portfolioType ===
                        'OpenEndedCollectiveInvestmentFundStableValueComposite' && (
                        <FeeRow
                          label="Total Expense"
                          value={latestFees?.totalExpense || null}
                          disabled={!editing}
                          onChange={createOnFeeChangeFunction('totalExpense')}
                        />
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </div>
      <FeeHistoryModal
        isOpen={showScheduleHistory}
        title="Management fee structure"
        toggle={() =>
          toggleScheduleHistoryModal((historyModal) => !historyModal)
        }
        data={{
          headers: ['ACCOUNT BALANCE', 'MANAGEMENT FEE'],
          rows: formatFeeScheduleHistory(portfolio?.feeSchedule),
        }}
        isValidUntilDate
      />
      <FeeHistoryModal
        isOpen={showDatedHistory}
        title="Fee Minimums"
        toggle={() => toggleDatedHistoryModal((historyModal) => !historyModal)}
        data={{
          headers: ['FEE', 'MANAGEMENT FEE'],
          rows: formatDatedFeeHistory(portfolio?.datedFees, portfolioType),
        }}
      />
    </>
  )
}
